<template>
  <div class="section-living section-bg full-bg" :class="{ dark: skinType }">
    <div
      class="section-title d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <div class="title">{{ title }}</div>
      </div>
      <slot
        name="header"
        class="section-header-right"
        style="margin-left: auto"
      ></slot>
    </div>
    <slot class="section-body d-flex"></slot>
  </div>
</template>
<script>
export default {
  name: 'section-living',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
};
</script>
<style scoped>
.section-living {
  background: linear-gradient(15deg, #1c4596, #181d62);
  /* background: linear-gradient(15deg, #181d62,#1c4596, ); */
  border: 2px solid #1b71cc;
  padding:1.5rem 1rem 1rem 2rem;
}
.section-bg {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.title-decoration {
  width: 4px;
  height: 1.5rem;
  background-color: #2e9bf3;
}
.section-body {
  flex-shrink: 1;
  flex-grow: 1;
}
.section-title {
  text-align: left;
  /* padding: 0.5rem 1rem; */
  font-size: 0.8rem;
  /* height: 2rem; */
  color: #333333;
}
.divider {
  height: 1px;
  background-color: #e6e6e6;
}
.divider.dark {
  background-color: #3344aa;
}
.title-center {
  text-align: center;
}
.title {
  color:#fff;
  font-size: 1.1rem;
}
.section-living.dark {
  background-color: #223388;
}
</style>
