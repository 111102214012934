<template>
  <div
  class="d-flex justify-content-between"
    style="
      width: 100%;
      height: 100%;
    "
  >
    <div
      class="none-width flex-grow-1 d-flex flex-column section-living"
      :class="{ dark: skinType }"
    >
    <!-- <div class="taber">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="tabCon"
        stretch = true
        tab-position=top
        :class="{ dark: skinType }"
      >
        <el-tab-pane label="今日用电" name="first"
        style="width:1%;height:15%;padding:0 0"></el-tab-pane>
        <el-tab-pane label="昨日用电" name="second"
        style="width:1%;height:15%;padding:0 0"></el-tab-pane>
        <el-tab-pane label="本月用电" name="third"
        style="width:1%;height:15%;padding:0 0"></el-tab-pane>
        <el-tab-pane label="上月用电" name="fourth"
        style="width:1%;height:15%;padding:0 0"></el-tab-pane>
      </el-tabs>
      </div> -->
      <div class="dark">
    <el-radio-group v-model="activeName" size="mini"
        type="card"
        @change="tabCon"
        :class="{ dark: skinType }"
        fill="#409EFF"
        style="width:100%;text-align: left;"
        >
      <el-radio-button :label="0" name="first">今日用电</el-radio-button>
      <el-radio-button :label="1" name="second">昨日用电</el-radio-button>
      <el-radio-button :label="2" name="third">本月用电</el-radio-button>
      <el-radio-button :label="3" name="fourth">上月用电</el-radio-button>
    </el-radio-group>
  </div>
      <echart1
        v-if="data"
        class="none-height flex-grow-1"
        :data="data"
      ></echart1>
      <no-data-temporarily
        v-else
        class="none-height flex-grow-1"
      ></no-data-temporarily>
    </div>
  </div>
</template>
<script>
import echart1 from '@/components/realtime-monitoring/ele-situation/utils/echart1.vue';
import { ele3 } from '@/api/ele-situation/ele3and4';
import { ele5 } from '@/api/ele-situation/ele5';
// import echart3 from '@/components/realtime-monitoring/ele-situation/utils/echart3.vue';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import TurnAskingMixin from '@/mixins/turn-ask';

export default {
  mixins: [TurnAskingMixin],
  data() {
    return {
      // activeName: 'first',
      activeName: 0,
      // 第一个图的数据
      data: null,
      // 第二个图的数据
      data3: null,
    };
  },
  props: {
    proId: String,
  },
  components: {
    echart1,
    // echart3,
    NoDataTemporarily,
  },
  watch: {
    proId() {
      this.requestEle3Data(0);
    },
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  created() {
    this.requestEle3Data(0);
  },
  methods: {
    requestEle4Data() {
      ele5({
        ProjectIdStr: this.proId,
      })
        .then((res) => {
          this.data3 = res;
        })
        .catch(() => {
          this.data3 = null;
        }).then(() => {
          this.turnAsking({
            routeName: this.routeName,
            nowRouteName: this.$route.name,
            func: this.requestEle4Data,
          });
        });
    },
    requestEle3Data(index) {
      const a = parseInt(index, 10) + 1;
      // 点击选择时间 再次请求
      ele3({
        //  默认项目id  Q0VGOTQ0QTA1QkI4QzlCQw
        ProjectIdStr: this.proId,
        // ProjectIdStr: 'NTExRERCNEJDNDA4QjI0MA',
        // 根据点击传入值
        DateType: a,
      })
        .then((res) => {
          this.data = [];
          if (res.length > 0) {
            for (let i = 0; i < res.length; i += 1) {
              const dataobj = {
                value: res[i].PowerConsumption,
                name: res[i].EquipmentName,
              };
              this.data.push(dataobj);
            }
          }
        })
        .catch(() => {
          this.data = null;
        });
    },
    tabCon(tab) {
      console.log('ab', tab);
      this.selectTab = tab;
      this.requestEle3Data(tab);
    },
  },
};
</script>
<style lang="less" scoped>
.el-tabs,
.el-tabs__content,
.el-tab-pane {
  flex-grow: 0 !important;
  padding: 0;
}
.eletab {
  width: 100%;
  height: 15%;
  display: flex;
  /* border-bottom: #d7d8da solid 0.1rem; */
}
.el-radio-button{
  // border: 2px solid #25C1FF;
  // border:1px solid #25C1FF;
  // background-color: #223388;
}
// .el-tabs__nav .el-tabs__item{
//   width: 100%;
//   height: 20%;
//   border:1px solid green;
// }
.tabcontent {
  width: 100%;
  display: flex;
}
.tabt {
  width: 12%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* border:tomato solid 0.1rem; */
  z-index: 99;
  margin-top: -0.1rem;
  border: rgba(255, 255, 255, 0) solid 0.1rem;
  border-bottom: rgba(255, 255, 255, 0) solid 0.1rem;
}
.ele3left {
  width: 49.3%;
  height: 100%;
}
.section-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  position: relative;
}
.lan {
  width: 0.3rem;
  height: 1.55rem;
  background: #2e9bf3;
  position: absolute;
  left: 1%;
}
.tip4text {
  font-size: 1.2rem;
  position: absolute;
  left: 3%;
}
.section-container+.section-container {
  margin-left:1rem;
}
// jinri
.taber{
  width: 100%;
  height: 20%;
  border:1px solid red;
}
.el-tabs__item{
  padding: 0px 0px!important;
}
.el-radio-button__inner{
  background: transparent;
}
.dark{
  width: 100%;
  text-align: start;
}
/deep/.dark .el-radio-button .el-radio-button__inner{
  // background-color: linear-gradient(15deg, #1C4596, #181D62);
  background-color: #181D62;
  // border: 1.5px solid #25C1FF;
  // box-shadow: 0px 0px 5px rgba(37, 193, 255, 1);

}
// 按钮
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    color: #fff;
    background-color: transparent!important;
    // box-shadow: 0px 0px 0px 0px rgba(37, 193, 255, 1);
}
// /deep/.dark .el-radio-button .el-radio-button__inner{
// border: 1px solid #1B71CC;
//     box-shadow: 0px 0px 0px 0px rgba(37, 193, 255, 1);
// }
// /deep/.el-radio-button:first-child .el-radio-button__inner{
//   box-shadow: 0px 0px 5px rgba(37, 193, 255, 1);
// }
.el-radio-button{
  // border: 1px solid #25C1FF;
box-shadow: 0px 0px 4px rgba(37, 193, 255, 0.8);
}
</style>
