/*eslint-disable*/
import {request} from '@/utils/request/request'
export function GetGongNengGaiKuangAndBarChart(query){
    return request ({
        url:'api/tongji/v1/GetGongNengGaiKuangAndBarChart',
        method:'get',
        params:query,
    })
}

export function GetDianNengXaoHaoForPieChart(query){
    return request ({
        url:'api/tongji/v1/GetDianNengXaoHaoForPieChart',
        method:'get',
        params:query,
    })
}

export function GetDianNengXiaoHaoForBarChart(query){
    return request ({
        url:'api/tongji/v1/GetDianNengXiaoHaoForBarChart',
        method:'get',
        params:query,
    })
}
export function GetYunxingshuju(query){
    return request ({
        url:'api/APPAPI_NEW/v1/JPTQ_SY_Count',
        method:'get',
        params:query,
    })
}