/*eslint-disable*/
// eslint-disable-next-line no-unused-vars
import { request } from '@/utils/request/request';
// eslint-disable-next-line import/prefer-default-export
export function getcapacity(query){
    return request ({
        url:'api/tongji/v1/GetKeKaiFangRongLiang',
        method:'get',
        params:query
    })
}
export function getfuheyucefenxi(query){
    return request({
        url:'api/tongji/v1/GetFuHeYuCeFenXi',
        method:'get',
        params:query
    })
}
export function getyongdianzhanbifenxi(query){
    return request({
        url:'api/tongji/v1/GetYongDianZhanBiFenXi',
        method:'get',
        params:query
    })
}
export function getbianyaqisunhaofenxi(query){
    return request({
        url:'api/tongji/v1/GetBianYaQiSunHao',
        method:'get',
        params:query
    })
}
export function getbianyaqiQueryTime(query){
    return request({
        url:'api/tongji/v1/GetBianYaQiSunHao',
        method:'get',
        params:query
    })
}
export function getmuxiandianliangbupinghengfenxi(query){
    return request({
        url:'api/tongji/v1/GetMuXianBuPingHeng',
        method:'get',
        params:query
    })
}
export function getmuzhuangtaipingjia(query){
    return request({
        url:'api/APPAPI_NEW/v1/JPTQ_OperationOverview_List',
        method:'get',
        params:query
    })
}

