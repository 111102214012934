<template>
  <el-row type="flex" class="justify-content-end" style="margin-bottom: 1rem">
    <NavImgButton
      class="nav-img-button cursor-pointer"
      v-for="(info, index) of navsList"
      :key="`nav-${index}`"
      type="primary"
      plain
      size="mini"
      :navImgInfo="info.meta"
      @navClick="routeToPage(info)"
    ></NavImgButton>
  </el-row>
</template>
<script>
import NavImgButton from '@/components/project-home/nav-img-button.vue';
import { projectNavs } from '@/router/project/index';

export default {
  name: 'navs-list',
  components: {
    NavImgButton,
  },
  data() {
    return {
      navsList: projectNavs,
    };
  },
  methods: {
    routeToPage(info) {
      this.$router.push({
        name: info.name,
      });
    },
  },
};
</script>
<style scoped>
.nav-img-button + .nav-img-button {
  margin-left: 1rem;
}
</style>
