<template>
  <div class="nav-img-button d-flex align-items-center cursor-pointer"
   @click="$emit('navClick')">
    <img class="nav-img" :src="navImgInfo.icon"/>{{navImgInfo.title}}
  </div>
</template>
<script>
export default {
  name: 'nav-img-button',
  props: {
    navImgInfo: {
      type: Object,
      default() {
        return {
          icon: null,
          title: '测试',
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>
<style scoped>
.nav-img-button{
  border: 1px solid #2ac1ff;
  border-radius: 4px;
  padding: 4px;
}
.nav-img{
  height:1.2rem;
}
</style>
