<template>
     <div class="analysis-box d-flex flex-grow-1 justify-content-around">
        <div class="analysis d-flex flex-column flex-grow-1 justify-content-around">
        <div class="electricity d-flex align-items-center">
          <div class="icon">
          <img src="../../assets/images/zhuyeshebeiyunxing/baojing.png" alt="">
          </div>
          <div class="d-flex flex-column" style="margin-left: 10px">
            <span style="font-size: 1rem; color: #fff"
              >{{ briefInfo[0].Name }}</span
            >
            <div class="decText ele-today">{{ briefInfo[0].Value }}
              <span style="font-size:1rem;color:#fff;">{{ briefInfo[0].Unit }}</span>
            </div>
          </div>
        </div>
           <div class="electricity d-flex align-items-center">
          <div class="icon">
          <img src="../../assets/images/zhuyeshebeiyunxing/yonghushuliang.png" alt="">
          </div>
          <div class="d-flex flex-column" style="margin-left: 10px">
            <span style="font-size: 1rem; color: #fff"
              >{{ briefInfo[1].Name }}</span
            >
            <div class="decText ele-today">{{ briefInfo[1].Value }}
              <span style="font-size:1rem;color:#fff;">{{ briefInfo[1].Unit }}</span>
              <!-- <div style="float:right;font-size:0.8rem;color:#fff;padding:0.2rem 0 0 0">
                {{ briefInfo[1].Unit }}</div> -->
            </div>
          </div>
        </div>
        </div>
        <div class="analysis-bos d-flex flex-column flex-grow-1 justify-content-around">
        <div class="electricity d-flex align-items-center">
          <div class="icon">
          <img src="../../assets/images/zhuyeshebeiyunxing/zongshuliang.png" alt="">
          </div>
          <div class="d-flex flex-column" style="margin-left: 10px">
            <span style="font-size: 1rem; color: #fff"
              >{{ briefInfo[2].Name }}</span
            >
            <div class="decText ele-today">{{ briefInfo[2].Value }}
              <span style="font-size:1rem;color:#fff;">{{ briefInfo[2].Unit }}</span>
            <!-- <div style="float:right;font-size:0.8rem;color:#fff;padding:0.4rem 1rem 0 0">
              {{ briefInfo[2].Unit }}</div> -->
            </div>
          </div>
        </div>
           <div class="electricity d-flex align-items-center">
          <div class="icon">
          <img src="../../assets/images/zhuyeshebeiyunxing/xiansunlv.png" alt="">
          </div>
          <div class="d-flex flex-column" style="margin-left: 10px">
            <span style="font-size: 1rem; color: #fff"
              >{{ briefInfo[3].Name }}</span
            >
            <div class="decText ele-today">{{ briefInfo[3].Value.toFixed(2) }}%</div>
          </div>
        </div>
        </div>
      </div>
</template>
<script>
import {
  GetYunxingshuju,
} from '@/api/enery-analysis/eneryanalysis';

export default {
  name: 'section-machine',
  props: {
    title: {
      type: String,
      default: '运行数据',
    },
  },
  data() {
    return {
      briefInfo: [],
    };
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  created() {
    this.requestOperatingList();
  },
  methods: {
    requestOperatingList() {
      GetYunxingshuju({
        ProjectIDstr: this.selectProject.IdStr,
      })
        .then((res) => {
          this.briefInfo = res;
        })
        .catch(() => {
          this.briefInfo = null;
        });
    },
  },
};
</script>
<style scoped>
.section-machine {
  background: linear-gradient(15deg, #1c4596, #181d62);
  border: 2px solid #1b71cc;
  padding:2rem 1rem 1rem 2rem;
}
.section-bg {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.title-decoration {
  width: 4px;
  height: 1.5rem;
  background-color: #2e9bf3;
}
.section-body {
  flex-shrink: 1;
  flex-grow: 1;
}
.section-title {
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  height: 2rem;
  color: #333333;
}
.divider {
  height: 1px;
  background-color: #e6e6e6;
}
.divider.dark {
  background-color: #3344aa;
}
.title-center {
  text-align: center;
}
.title {
  color:#fff;
  font-size: 1.5rem;
}
.section-machine.dark {
  background-color: #223388;
}
/* 运行 */
.analysis-box{
   width: 97%;
  height: 100%;
  margin: 0 auto;
}
.analysis-bos{
  width: 48%;
}
.analysis{
  width: 48%;
}
.electricity{
  height: 30%;
}
.icon img{
  width: 3.2rem;
  height: 3.2rem;;
}
.use-ele-icon {
  font-size: 2.2rem;
  color: #25c1ff;
}
.decText {
  display: inline-block;
  font-size: 1.6rem;
  color:#25c1ff;
  margin-top: 0.6rem;
  text-align: start;
}
.hu{
  float: left;
  color:#fff;
}
</style>
