<template>
  <div
    id="main1"
    ref="main1"
  ></div>
</template>
<script>
import { initResizeListener } from '@/utils/utils';

export default {
  data() {
    return {
      myChart1: null,
    };
  },
  props: {
    data: Array,
  },
  watch: {
    data(newVal) {
      this.setChartOption(newVal);
    },
    skinType() {
      this.setChartOption(this.data);
    },
  },
  mounted() {
    this.setChartOption(this.data);
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  methods: {
    setChartOption(chartData) {
      if (this.myChart1 === null) {
        this.myChart1 = this.$echarts.init(this.$refs.main1);
        initResizeListener(this.myChart1, this.$refs.main1);
      }
      if (chartData.length === 0) {
        this.myChart1.clear();
      } else {
        const option11 = {
          title: {
            text: '',
            top: 5,
            left: 3,
            textStyle: {
              // fontWeight: 'bold',
              fontSize: '12',
              // color: 'auto',
              color: '#fff',
            },
          },
          grid: {
            containLabel: true,
          },
          label: {
            formatter: '{b|{b}:}{c|{c}} \n {per|{d}%\n}  ',
            rich: {
              a: {
                // color: '#6E7079',
                color: '#fff',
                lineHeight: 22,
                align: 'center',
              },
              b: {
                // color: this.skinType ? '#fff' : '#4C5058',
                // color: 'auto',
                color: '#fff',
                fontSize: '12',
              },
              c: {
                // color: 'auto',
                color: '#fff',
                fontSize: '12',
              },
              per: {
                // color: 'auto',
                color: '#fff',
                // padding: [3, 4],
                borderRadius: 4,
                fontSize: '12',
                align: 'center',
              },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: ' {b}：{c} ',
          },
          legend: {
            // orient: 'vertical',
            // left: '5%',
            // top: '20%',
            icon: 'circle',
            // itemWidth: 10, // 设置宽度
            // itemHeight: 10,
            top: 'bottom',
            itemGap: 15,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              fontSize: '12',
              // color: 'auto',
              color: '#fff',
            },
          },
          series: [
            {
              type: 'pie',
              // radius: ['28%', '45%'],
              radius: [30, 85],
              center: ['50%', '50%'],
              data: chartData,
              roseType: 'radius',
              itemStyle: {
                borderRadius: 5,
                normal: {
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
              },
              labelLine: {
                normal: {
                  length: 2,
                  length2: 10,
                  lineStyle: {
                    color: '#fff',
                  },
                },
              },
              // labelLine: {
              //   length: 0,
              // },
            },
          ],
          color: [
            '#5BFFB0',
            '#00FFFF',
            '#FFDE5B',
            '#2AC1FF',
            '#4472C4',
            '#E6E6E6'],
        };
        this.$nextTick(() => {
          this.myChart1.setOption(option11, true);
        });
      }
    },
  },
};
</script>
