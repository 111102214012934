<template>
  <!-- <div class="section-container section-bg full-bg" :class="{ dark: skinType }"> -->
    <!-- <div
      class="section-title d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <div class="title">{{ title }}</div>
      </div>
      <slot
        name="header"
        class="section-header-right"
        style="margin-left: auto"
      ></slot>
    </div> -->
    <div class="consumption-box d-flex flex-grow-1 justify-content-around">
      <div class="consumption d-flex flex-column flex-grow-1 justify-content-around">
        <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/zyl.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >今日总用电量(kWh)：</span
            >
            <div class="decText ele-today">{{ briefInfo.YongDianLiang }}</div>
          </div>
        </div>
        <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/gf.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >今日光伏发电(kWh)：</span
            >
            <div class="decText ele-today">{{ briefInfo.GuangFuDianliang }}</div>
          </div>
        </div>
        <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/gd.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >今日电网购电(kWh)：</span
            >
            <div class="decText ele-today">{{ briefInfo.DianWangGouDian}}</div>
          </div>
        </div>
      </div>
      <!-- er -->

       <div class="consumption-bos d-flex flex-column flex-grow-1 justify-content-around">
        <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/zh.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >折合碳排放(t)</span
            >
            <div class="decTexts ele-today">{{ briefInfo.ZheHeTanPaiFang.toFixed(2) }}</div>
          </div>
        </div>
        <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/js.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >碳排放减少(t)</span
            >
            <div class="decTexts ele-today">{{ briefInfo.TaiPaiFangJianShao.toFixed(2) }}</div>
          </div>
        </div>
         <div class="electricity d-flex align-items-center">
          <!-- <span class="use-ele-icon iconfont iconjintian"></span> -->
          <img src="../../assets/images/zhuyegaikuang/tpf.png" alt="">
          <div class="d-flex flex-column" style="margin-left: 12px">
            <span style="font-size: 1rem; color: #fff"
              >实际碳排放(t)</span
            >
            <div class="decTexts ele-today">{{ briefInfo.ShiJiTanPaiFang.toFixed(2) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <slot class="section-body d-flex"></slot> -->
  <!-- </div> -->
</template>
<script>
import TurnAskingMixin from '@/mixins/turn-ask';
import { formatDate } from '@/utils/utils';

import {
  GetGongNengGaiKuangAndBarChart,
} from '@/api/enery-analysis/eneryanalysis';

export default {
  mixins: [TurnAskingMixin],
  name: 'section-container',
  data() {
    return {
      selectNode: null,
      seriesData: [],
      briefInfo: null,
      periodType: 'Day',
      selectPeriod: [
        // formatDate(new Date(), 'yyyy-MM-01'),
        formatDate(new Date(), 'yyyy-MM-dd'),
        formatDate(new Date(), 'yyyy-MM-dd'),
      ],
    };
  },
  props: {
    // proId: String,
    // selectPeriod: Array,
    // periodType: String,
    title: {
      type: String,
      default: '概况',
    },
  },
  watch: {
    // proId() {
    //   // this.requestDataList();
    //   this.requestPowerProfile();
    // },
    periodType() {
      this.selectPeriod = this.getInitialPeriod();
    },
    selectPeriod() {
      this.requestPowerProfile();
    },
    selectNode() {
      this.requestDataList();
    },

  },
  created() {
    // this.requestDataList();
    this.requestPowerProfile();
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    onSubmit() {
      this.requestDataList();
    },
    requestDataList() {
      if (this.selectNode === null) {
        return;
      }
      this.requestPowerProfile();
    },
    requestPowerProfile() {
      // if (!this.proId) {
      //   return;
      // }
      console.log(96999);
      const [start, end] = this.selectPeriod;
      GetGongNengGaiKuangAndBarChart({
        ProjectIDstr: this.selectProject.IdStr,
        // DateType: this.periodType,
        // BeginDate: start,
        // EndDate: end,
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        CarbonEmissions: 1,
      })
        .then((res) => {
          console.log(555555);
          this.briefInfo = res.GongNengGaiKuang;
          console.log(this.briefInfo);
          console.log('hihhh');
          console.log(this.requestPowerProfile);
        })
        .catch(() => {
          this.briefInfo = null;
        });
    },
  },
};
</script>
<style scoped>
.section-container {
  background: linear-gradient(15deg, #1c4596, #181d62);
  /* border: 2px solid #1b71cc; */
  padding: 2rem 1rem 1rem 2rem;
}
.section-bg {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.title-decoration {
  width: 4px;
  height: 1.5rem;
  background-color: #2e9bf3;
}
.section-body {
  flex-shrink: 1;
  flex-grow: 1;
}
.section-title {
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  height: 2rem;
  color: #333333;
}
.divider {
  height: 1px;
  background-color: #e6e6e6;
}
.divider.dark {
  background-color: #3344aa;
}
.title-center {
  text-align: center;
}
.title {
  color: #fff;
  font-size: 1.5rem;
}
.section-container.dark {
  background-color: #223388;
}
/* jinri */
.consumption-box {
  width: 95%;
  height: 100%;
  margin: 0 auto;
}
.consumption {
  width: 50%;
  /* height: 30%; */
}

.electricity{
  /* width: 50%; */
  height: 30%;
}
.electricity img {
  width: 2.8rem;
  height: 2.5rem;
}
.use-ele-icon {
  font-size: 2.2rem;
  color: #25c1ff;
}
.decText {
  font-size: 1.6rem;
  color: #00cc69;
  margin-top: 0.6rem;
  text-align: start;
}
.decTexts{
  font-size: 1.6rem;
  color: #237AE4;
  margin-top: 0.6rem;
  text-align: start;
}
</style>
