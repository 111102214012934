import { request } from '@/utils/request/request';

export function ele3(query) {
  return request({
    url: 'api/tongji/v1/GetYongDianLiangForBYQ',
    method: 'get',
    params: query,
  });
}
export function ele4(query) {
  return request({
    url: 'api/tongji/v1/GetQiYeZongYongDianLiang',
    method: 'get',
    params: query,
  });
}
