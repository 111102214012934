<template>
  <div class="d-flex flex-column flex-grow-1">
    <NavsList class="align-self-start"></NavsList>
    <template v-if="selectProject">
      <el-row class="row-container none-height flex-grow-1 d-flex" :gutter="12">
        <el-col :span="6" class="d-flex flex-column">
          <SectionLiving title="概况" class="none-height flex-grow-1">
            <SectionContainer
              class="flex-grow-1"
              :proId="selectProject.IdStr"
            ></SectionContainer>
          </SectionLiving>
        </el-col>
        <el-col :span="12" class="d-flex flex-column">
          <SectionLiving title="碳排放占比(t)" class="none-height flex-grow-1">
            <div
              class="
                flex-grow-1
                d-flex
                flex-row
                align-items-center
                justify-content-around
                wukuaicont
              "
            >
              <div class="wukuaicontshang">
                <div class="wushangzuo">
                  <Charts class="flex-grow-1" :options="CarbonData"></Charts>
                </div>
              </div>
              <div class="wukuaicontxia">
                <div class="wuxiazuo">
                  <Charts class="flex-grow-1" :options="PublicData"></Charts>
                </div>
              </div>
            </div>
            <!-- </SectionCarbon> -->
          </SectionLiving>
        </el-col>
        <el-col :span="6" class="d-flex flex-column">
          <SectionLiving title="运行数据" class="none-height flex-grow-1">
            <SectionMachine class="flex-grow-1"></SectionMachine>
          </SectionLiving>
        </el-col>
      </el-row>
      <el-row class="row-container none-height flex-grow-1 d-flex" :gutter="12">
        <el-col :span="6" class="d-flex flex-column">
          <SectionLiving
            class="none-height flex-grow-1"
            style="padding: 1rem 1rem 1rem 1rem"
          >
            <div class="ele-situation d-flex flex-column"></div>
            <Tanquyu :proId="selectProject.IdStr"></Tanquyu>
          </SectionLiving>
        </el-col>
        <el-col :span="12" class="d-flex flex-column">
          <SectionLiving title="当前负荷" class="none-height flex-grow-1">
            <Charts class="flex-grow-1" :options="tendencyData"></Charts>
          </SectionLiving>
        </el-col>
        <el-col :span="6" class="d-flex flex-column">
          <SectionLiving title="状态评价" class="none-height flex-grow-1"
           v-if="lossInfoList">
            <div class="number d-flex flex-row ">
              已运行<span>{{ this.res.Day }}</span
              >天
            </div>
            <div class="excellent d-flex flex-row">
              <div class="yuan"></div>
              <span class="device">设备运行状态:</span>
              <div class="youxiu" style="color: #00cc69">{{ this.res.Message }}</div>
            </div>
            <Charts
              class="flex-grow-1 condition"
              :options="conditionData"
            ></Charts>
            <Charts class="flex-grow-1 biax" :options="biaxData"></Charts>
          </SectionLiving>
          <NoDataTemporarily class="flex-grow-1 bartion" v-else></NoDataTemporarily>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
<script>
import TurnAskingMixin from '@/mixins/turn-ask';
import SectionContainer from '@/components/utils/section-container.vue';
import { ele3 } from '@/api/ele-situation/ele3and4';
import SectionMachine from '@/components/utils/section-machine.vue';
import SectionLiving from '@/components/utils/section-living.vue';
import NavsList from '@/components/project-home/navs-list.vue';
import { getSelectCourtIds, formatDate } from '@/utils/utils';
import { ele5 } from '@/api/ele-situation/ele5';
import { getShebeiInfo } from '@/api/switchroom';
import Charts from '../general/chart.vue';
import Tanquyu from '../utils/tanquyu.vue';
import { getyongdianzhanbifenxi } from '../../api/statistical-analysis/statisticalanalysis';
import NoDataTemporarily from '../utils/no-data-temporarily.vue';

export default {
  name: 'project-home',
  mixins: [TurnAskingMixin],
  components: {
    SectionContainer,
    SectionMachine,
    SectionLiving,
    Tanquyu,
    NavsList,
    Charts,
    NoDataTemporarily,
  },
  data() {
    return {
      select: '',
      title: '',
      data: null,
      QuYuData: null,
      data3: null,
      lossInfoList: null,
      activeName: 'first',
      selectPeriodType: -1,
      periodTypeList: [
        { name: '今日用电', type: 0 },
        { name: '昨日用电', type: 1 },
        { name: '本月用电', type: 2 },
        { name: '上月用电', type: 3 },
      ],
      // biaxData: {
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       type: 'none',
      //     },
      //   },
      //   legend: {
      //     // data: ['2011年', '2012年'],
      //   },
      //   grid: {
      //     left: '3%',
      //     right: '4%',
      //     // bottom: '150%',
      //     top: '115%',
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     type: 'value',
      //     // boundaryGap: [0, 0.01],
      //     show: false,
      //   },
      //   yAxis: [{
      //     type: 'category',
      //     inverse: true,
      //     axisLabel: {
      //       show: true,
      //       textStyle: {
      //         color: '#fff',
      //       },
      //     },
      //     splitLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     axisLine: {
      //       show: false,
      //     },
      //     data: ['1#变压器', '2#变压器', '3#变压器'],
      //   },
      //   {
      //     type: 'category',
      //     inverse: true,
      //     axisTick: 'none',
      //     axisLine: 'none',
      //     show: true,
      //     axisLabel: {
      //       textStyle: {
      //         color: '#ffffff',
      //         fontSize: '12',
      //       },
      //     },
      //     data: [30, 73, 85],
      //   }],
      //   series: [
      //     // {
      //     //   // name: '2011年',
      //     //   type: 'bar',
      //     //   data: [18203, 23489, 29034, 104970, 131744, 630230],
      //     // },
      //     {
      //       // name: '值',
      //       type: 'bar',
      //       // zlevel: 1,
      //       itemStyle: {
      //         normal: {
      //           barBorderRadius: 30,
      //           color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
      //             offset: 0,
      //             color: 'rgb(57,89,255)',
      //           }, {
      //             offset: 1,
      //             color: 'rgb(46,200,207)',
      //           }]),
      //           // color: {
      //           //   type: 'linear', // 线性渐变
      //           //   x: 0,
      //           //   y: 0,
      //           //   x2: 0,
      //           //   y2: 1,
      //           //   colorStops: [{
      //           //     offset: 0,
      //           //     color: 'rgb(57,89,255,1)', // 0%处的颜色为红色
      //           //   }, {
      //           //     offset: 1,
      //           //     color: 'rgb(46,200,207,1', // 100%处的颜色为蓝
      //           //   }],
      //           // },
      //         },
      //       },
      //       barheight: 60,
      //       barWidth: 10,
      //       data: [18203, 23489, 29034],
      //     },
      //     // {
      //     //   name: '2012年',
      //     //   type: 'bar',
      //     //   data: [19325, 23438, 31000, 121594, 134141, 681807],
      //     // },
      //   ],
      // },
      ConsumerData: {
        title: {
          text: '各台变压器用电量',
          top: 0,
          left: 10,
          textStyle: {
            fontWeight: 'bold',
            fontSize: '14',
            color: 'auto',
          },
        },
        grid: {
          containLabel: true,
        },
        label: {
          formatter: '{b|{b}:}{c|{c}} \n {per|{d}%\n}  ',
          rich: {
            a: {
              color: '#6E7079',
              lineHeight: 22,
              align: 'center',
            },
            b: {
              color: '#fff',
              // color: this.skinType ? '#fff' : '#4C5058',
              fontSize: '12',
            },
            c: {
              // color: 'auto',
              color: '#fff',
              fontSize: '12',
            },
            per: {
              // color: 'auto',
              color: '#fff',
              padding: [3, 4],
              borderRadius: 4,
              align: 'center',
            },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: ' {b}：{c} ',
        },
        legend: {
          // orient: 'vertical',
          left: '2%',
          // top: '40%',
          top: 'bottom',
          itemGap: 5,
          itemWidth: 20,
          itemHeight: 10,
          textStyle: {
            fontSize: '12',
            color: 'auto',
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['25%', '35%'],
            center: ['50%', '38%'],
            // data: chartData,
            data: [
              { value: 1048, name: '1#变压器' },
              { value: 735, name: '2#变压器' },
              { value: 580, name: '3#变压器' },
              { value: 484, name: '4#变压器' },
              { value: 300, name: '5#变压器' },
              { value: 300, name: '6#变压器' },
            ],
            itemStyle: {
              normal: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          },
        ],
        color: [
          '#FFAF8E',
          '#667EEB',
          '#75B2FB',
          '#08BED3',
          '#F67CA3',
          '#7FC4C2',
          '#7a81b5',
        ],
      },
    };
  },
  watch: {
    selectProject() {
      this.requestLoadForcasting();
      this.requestEleAnalysis();
      this.requestFacilityInfo();
    },
  },
  created() {
    this.requestEleAnalysis();
    this.requestLoadForcasting();
    this.requestFacilityInfo();
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
    accountId() {
      return this.$store.getters.accountId;
    },
    call() {
      return this.$store.getters.call;
    },
    // 碳排放占比
    CarbonData() {
      if (this.QuYuData == null) {
        return null;
      }
      return {
        color: [
          '#FFAF8E',
          '#667EEB',
          '#75B2FB',
          '#08BED3',
          '#F67CA3',
          '#7FC4C2',
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}({d}%)',
        },
        legend: {
          top: 'bottom',
          icon: 'circle',
          itemWidth: 10, // 设置宽度
          itemHeight: 10,
          textStyle: {
            color: '#fff',
          },
        },
        calculable: true,
        title: {
          text: '',
          left: 'left',
          textStyle: {
            fontSize: 10,
            color: '#fff',
            fontWeight: 'normal',
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['50%', '40%'],
            label: {
              formatter: '{b} : \n{c}({d}%)',
              color: '#fff',
            },
            labelLine: {
              normal: {
                length: 3,
                length2: 10,
                lineStyle: {
                  color: '#fff',
                },
              },
            },
            data: this.QuYuData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    // 碳排放占比2
    PublicData() {
      if (this.QuYuData == null) {
        return null;
      }
      return {
        color: this.skinType
          ? ['#FFAF8E', '#667EEB', '#75B2FB', '#08BED3', '#F67CA3', '#7FC4C2']
          : ['#237AE4', '#00FFFF ', '#FFDE5B', '#2AC1FF', '#5BFFB0'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}t ({d}%)',
        },
        legend: {
          top: 'bottom',
          icon: 'circle',
          itemWidth: 10, // 设置宽度
          itemHeight: 10,
          textStyle: {
            color: '#fff',
          },
        },
        calculable: true,
        title: {
          text: '',
          color: 'white',
          left: 'left',
          textStyle: {
            fontSize: 10,
            color: '#fff',
            fontWeight: 'normal',
          },
        },
        series: [
          {
            name: '分项用电占比',
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['50%', '40%'],
            label: {
              formatter: '{b} : \n{c}({d}%)',
              color: '#fff',
            },
            data: this.FenXiangData,
            labelLine: {
              normal: {
                length: 3,
                length2: 10,
                lineStyle: {
                  color: '#fff',
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    // 当前负荷
    tendencyData() {
      if (this.data3 === null) {
        return null;
      }
      return {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '40',
          left: '10',
          right: '20',
          bottom: '20',
          containLabel: true,
        },
        legend: {
          top: '5',
          left: 'left',
          icon: 'circle',
          itemWidth: 10, // 设置宽度
          itemHeight: 10,
          textStyle: {
            color: 'auto',
          },
        },
        xAxis: [
          {
            type: 'category',
            // data: [20, 52, 100, 34, 30, 150, 220],
            data: this.data3.xAxisData,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            axisLabel: {},
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kW',
            nameTextStyle: {
              padding: [0, -15, -15, 0],
            },
            axisLabel: {
              show: true,
              formatter: '{value}',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#ccc',
                opacity: '0.23',
                type: 'dashed',
              },
            },
          },
          {
            // 隐藏掉右边的纵坐标
            show: true,
            type: 'value',
            name: '%',
            nameTextStyle: {
              color: '#fff',
            },
            axisLabel: {
              color: '#fff',
              formatter: '{value}',
            },
            axisLine: {
              color: '#fff',
            },
            splitLine: {
              lineStyle: {
                color: '#ccc',
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            name: '负荷',
            type: 'bar',
            barGap: '220%',
            yAxisIndex: 0,
            barWidth: '15%', // 柱图宽度,
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              verticalAlign: 'middle',
              textStyle: {
                // 数值样式
                color: '#fff',
                fontSize: 10,
              },
            },
            color: {
              type: 'linear', // 线性渐变
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#00BEFE', // 0%处的颜色为红色
                },
                {
                  offset: 1,
                  color: '#007EEE', // 100%处的颜色为蓝
                },
              ],
            },
            data: this.data3.seriesData[0].Data,
          },
          {
            name: '负载率',
            type: 'bar',
            yAxisIndex: 1,
            barWidth: '15%', // 柱图宽度
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              // distance: 20, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。
              verticalAlign: 'middle',
              textStyle: {
                // 数值样式
                color: '#fff',
                fontSize: 10,
              },
            },
            color: {
              type: 'linear', // 线性渐变
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#FFAF47', // 0%处的颜色为红色
                },
                {
                  offset: 1,
                  color: '#F9912C', // 100%处的颜色为蓝
                },
              ],
            },
            data: this.data3.seriesData[1].Data,
          },
        ],
        color: [
          '#0079ED',
          '#F88C28',
          '#667EEB',
          '#75B2FB',
          '#08BED3',
          '#F67CA3',
          '#7FC4C2',
          '#7a81b5',
        ],
      };
    },
    // 状态评价
    conditionData() {
      if (this.lossInfoList == null) {
        return null;
      }
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
        },
        radar: [
          {
            name: {
              show: true,
              formatter: null,
              textStyle: {
                // 设置颜色
                color: '#fff',
              },
            },
            indicator: this.arr_LDT_Di,
            // [
            //   { text: '1#变压器', max: 100 },
            //   { text: '2#变压器', max: 100 },
            //   { text: '3#变压器', max: 100 },
            // ],
            center: ['50%', '68%'],
            radius: 70,
          },
        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item',
            },
            areaStyle: {},
            data: [
              {
                value: this.arr_BYQNum,
                // value: [60, 73, 85, 40],
                name: '状态评价',
              },
            ],
          },
        ],
      };
    },
    // 状态评价2
    biaxData() {
      if (this.lossInfoList == null) {
        return null;
      }
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          top: '125%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: this.arr_BYQName,
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12',
              },
            },
            data: this.arr_BYQNum,
            // data: [1, 2, 3],
          },
        ],
        series: [
          {
            type: 'bar',
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgb(57,89,255)',
                  },
                  {
                    offset: 1,
                    color: 'rgb(46,200,207)',
                  },
                ]),
              },
            },
            barheight: 0,
            barWidth: 10,
            // data: this.lossInfoList.List.Score,
            data: this.arr_BYQNum,
          },
        ],
      };
    },
  },
  methods: {
    requestEle3Data(index) {
      if (!this.selectProject) {
        return;
      }
      const a = parseInt(index, 10) + 1;
      ele3({
        ProjectIdStr: this.selectProject.IdStr,
        // 根据点击传入值
        DateType: a,
      })
        .then((res) => {
          this.data = [];
          if (res.length > 0) {
            for (let i = 0; i < res.length; i += 1) {
              const dataobj = {
                value: res[i].PowerConsumption,
                name: res[i].EquipmentName,
              };
              this.data.push(dataobj);
            }
          }
        })
        .catch(() => {
          this.data = null;
        });
    },
    initData(value2) {
      this.tricity = value2;
    },
    requestLoadForcasting() {
      if (!this.selectProject) {
        return;
      }
      getyongdianzhanbifenxi({
        ProjectIDstr: this.selectProject.IdStr,
        Date: formatDate(new Date(), 'yyyy-MM-dd'),
      }).then((res) => {
        this.QuYuData = res.QuYuData;
        this.FenXiangData = res.FenXiangData;
      });
    },
    // 当前负荷
    requestEleAnalysis() {
      if (!this.selectProject) {
        return;
      }
      ele5({
        ProjectIDstr: this.selectProject.IdStr,
      })
        .then((res) => {
          this.data3 = res;
        })
        .catch(() => {
          this.data3 = null;
        });
    },
    // 状态评价
    requestFacilityInfo() {
      if (!this.selectProject) {
        return;
      }
      getShebeiInfo({
        UIDstr: this.accountId,
        ProjectIDstr: this.selectProject.IdStr,
      }).then((res) => {
        this.res = res;
        this.lossInfoList = res.List;
        // eslint-disable-next-line no-array-constructor
        this.arr_BYQName = new Array();
        // eslint-disable-next-line no-array-constructor
        this.arr_BYQNum = new Array();
        // eslint-disable-next-line no-array-constructor
        this.arr_LDT_Di = new Array();
        console.log('00000000000000000');
        for (let i = 0; i < this.lossInfoList.length; i += 1) {
          this.arr_BYQName.push(this.lossInfoList[i].TName);
          this.arr_BYQNum.push(this.lossInfoList[i].Score);
          this.arr_LDT_Di.push({ text: this.lossInfoList[i].TName, max: 100 });
        }
        console.log('111111111111111');
        console.log(this.arr_LDT_Di);
        console.log(this.arr_BYQNum);
        console.log(this.lossInfoList);
      });
    },
    getRoomIds() {
      return getSelectCourtIds(this.selectProject);
    },
    getRechargingBoxSize() {
      const dom = this.$refs.rechargingChart.$el;
      console.log('dom', dom.clientWidth, dom.clientHeight);
      this.rechargingSize.width = dom.clientWidth * 0.94 - 76;
    },
  },
};
</script>
<style scoped>
.row-container + .row-container {
  margin-top: 12px;
}
.align-self-start {
  width: 100%;
}
/* aa */
.wukuaicont {
  width: 95%;
  height: 100%;
  /* border: 1px solid pink; */
  display: flex;
  /* flex-direction: column; */
  /* flex-wrap: wrap; */ /*是否上下排列 */
  margin: 2% 2.5%;
}
.wukuaicontshang {
  width: 50%;
  height: 100%;
  /* background-color: burlywood; */
  display: flex;
  /* flex-direction: column; */
  /* margin: 8.5% 0; */
}
.wukuaicontxia {
  width: 50%;
  height: 100%;
  display: flex;
  /* border:1px solid red; */
  /* flex-direction: column; */
  /* background-color: chartreuse; */
  /* margin: 8.5% 0; */
}
.wushangzuo {
  width: 95%;
  height: 100%;
  display: flex;
  /* background: chocolate; */
}
.wushangyou {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: #d8beab; */
}
.wuxiazuo {
  width: 95%;
  height: 100%;
  display: flex;
  /* background: #c3dfc7; */
}
.wuxiayou {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: #d4abd8; */
}
.jinri {
  width: 100%;
  height: 20%;
}
.el-button + .el-button {
  margin-left: 0% !important;
  border: 1px solid #25c1ff;
  background-color: #223388;
  color: #fff;
}
.various {
  width: 95%;
  margin: 0 auto;
  text-align: start;
  position: absolute;
  top: 20%;
}
/* 状态评价 */

.yuan {
  width: 3%;
  height: 0.8rem;
  margin-top: 1.2%;
  border-radius: 50%;
  background-color: #2e9bf3;
}
.device {
  width: 28%;
}
.number {
  width: 30%;
  position: relative;
  bottom: 6%;
  left: 70%;
}
.number span {
  font-size: 1.2rem;
  padding: 0 3%;
  color: #25c1ff;
}

/* er */
.section-header {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0 0.5rem;
}
.ele-container + .ele-container {
  margin-top: 1rem;
}
.brief-info {
  height: 10rem;
}
.condition {
  width: 100%;
  height: 14rem;
}
.biax {
  width: 100%;
  height: 7rem;
}
.bartion{
  background: linear-gradient(
15deg, #1c4596, #181d62);
border:2px solid #1b71cc;
}
</style>
